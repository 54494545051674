<script>
//IMPORT SCRIPT
import * as THREE from '../../node_modules/three';
import {GLTFLoader} from '../../node_modules/three/examples/jsm/loaders/GLTFLoader';
import {RGBELoader} from '../../node_modules/three/examples/jsm/loaders/RGBELoader.js';
import gsap from "../../node_modules/gsap/dist/gsap";
import ScrollTrigger from "../../node_modules/gsap/dist/ScrollTrigger";
import Flip from "../../node_modules/gsap/dist/Flip";
import Draggable from "../../node_modules/gsap/dist/Draggable";
import ScrollToPlugin from "../../node_modules/gsap/dist/ScrollToPlugin";
import MotionPathPlugin from "../../node_modules/gsap/dist/MotionPathPlugin";

export default {
  name: 'HomePage',
  mounted() {

    let renderer,camera,scene,mixer,model,mouseMesh,light, clock = new THREE.Clock(),mouse = {x: 0,y: 0};
    gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin, ScrollToPlugin);

    const loadingManager = new THREE.LoadingManager();
//    loadingManager.onStart = function (url,item,total){
//      console.log(url,item,total);
//    }
    

    const progressBar = document.getElementById('progress-bar');

    loadingManager.onProgress = function (url,loaded,total){
      progressBar.value = (loaded/total) * 100;
    }


    const progressBarContainer = document.querySelector('.progress-bar-container');
    gsap.set(progressBarContainer, {opacity: 1,display: "flex",});
    loadingManager.onLoad = function (){
      gsap.to(progressBarContainer, {opacity: 0, display: "none", duration: 1});
    }


//    loadingManager.onError = function (url,item,total){
//      console.log(url,item,total);
//    }



    init();
    function init() {


      //SCENE SETUP
      scene = new THREE.Scene();

      //CAMERA
      camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set( 0, 0.4, 3 );
      camera.rotation.set( 0.2, 0, 0 );

      //RENDERER
      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.render(scene, camera);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild( renderer.domElement );  

      const rectLight1 = new THREE.RectAreaLight( 0x18FEFE, 200, 0.29, 0.35 );
      rectLight1.position.set( -0.4, 1.72, -0.1 );
      rectLight1.lookAt( -0.4, 1.72, 0 );
      const rectLight2 = new THREE.RectAreaLight( 0x18FEFE, 20, 0.24, 0.25 );
      rectLight2.position.set( -0.19, 1.18, 0.2 );
      rectLight2.lookAt( -0.19, 1.18, 0 );
      const rectLight3 = new THREE.RectAreaLight( 0x18FEFE, 100, 0.05, 0.27 );
      rectLight3.position.set( 0.16, 1.44, 0.2 );
      rectLight3.lookAt( 0.16, 1.44, 0 );
      const rectLight4 = new THREE.RectAreaLight( 0x18FEFE, 400, 0.29, 0.35 );
      rectLight4.position.set( 0.36, 1.01, 0 );
      rectLight4.lookAt( 0.31, 1.01, 0.1 );

      //WHITE SPOTLIGHT MAIN_LEFT
      const spotLight1 = new THREE.SpotLight(0xffffff);
      spotLight1.position.set( 5, 0, 3 );
      spotLight1.target.position.set( 1, 1.5, 0 );
      spotLight1.intensity = 3;
      spotLight1.angle = Math.PI * 0.3,
      spotLight1.distance = 65,
      spotLight1.penumbra = 1.5,
      spotLight1.decay = 5;
      spotLight1.castShadow = true;
      spotLight1.shadow.mapSize.width = 512;
      spotLight1.shadow.mapSize.height = 512;
      spotLight1.shadow.camera.near = 0.5;
      spotLight1.shadow.camera.far = 500;
      spotLight1.shadow.focus = 1;
      scene.add( spotLight1,spotLight1.target);

      //WHITE SPOTLIGHT SIDE_RIGHT
      const spotLight2 = new THREE.SpotLight(0xffffff);
      spotLight2.position.set(-2.5,2.6, 3.2);
      spotLight2.target.position.set( 0.4, 0.51, 0 );
      spotLight2.intensity = 2.5;
      spotLight2.angle = 0.22,
      spotLight2.distance = 55,
      spotLight2.penumbra = 1,
      spotLight2.decay = 10;
      spotLight2.castShadow = true;
      spotLight2.shadow.mapSize.width = 512;
      spotLight2.shadow.mapSize.height = 512;
      spotLight2.shadow.camera.near = 0.5;
      spotLight2.shadow.camera.far = 500;
      spotLight2.shadow.focus = 1;
      scene.add( spotLight2, spotLight2.target );

      //WHITE SPOTLIGHT BODY_CENTER
      const spotLight3 = new THREE.SpotLight(0xffffff);
      spotLight3.position.set(0,1, 3.2);
      spotLight3.target.position.set( 0.51, 1.7, -4 );
      spotLight3.intensity = 3;
      spotLight3.angle = 0.19,
      spotLight3.distance = 27,
      spotLight3.penumbra = 1,
      spotLight3.decay = 8.5;
      spotLight3.castShadow = true;
      spotLight3.shadow.mapSize.width = 512;
      spotLight3.shadow.mapSize.height = 512;
      spotLight3.shadow.camera.near = 0.5;
      spotLight3.shadow.camera.far = 500;
      spotLight3.shadow.focus = 1;
      scene.add( spotLight3, spotLight3.target );

      //WHITE SPOTLIGHT SUN_LIGHT
      const directionalLight = new THREE.DirectionalLight( 0xffffff);
      directionalLight.position.set( 1, 2, -1 );
      directionalLight.target.position.set(0,0,0);
      directionalLight.intensity = 0.75;
      directionalLight.decay = 3;
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 512;
      directionalLight.shadow.mapSize.height = 512;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 500;
      scene.add( directionalLight );

      light = new THREE.PointLight(0xff00ff);
      light.position.set(0, 0, 15);
      scene.add(light);


      //HDR
      const hdrTextureURL = new URL('/public/image/factory.hdr', import.meta.url)
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 0.5;
      const hdrloader = new RGBELoader(loadingManager);
      hdrloader.load(hdrTextureURL,function (texture){
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;
      });


    const spacesuitmodel = new GLTFLoader(loadingManager);
    spacesuitmodel.load( '/models/lyceric.glb', ( spacesuit ) => {
      model = spacesuit.scene;
      model.rotation.x = 0;
      model.rotation.y = 0;
      model.rotation.z = 0;
      model.scale.set(1,1,1);
      model.position.set(0,0,0);
      model.traverse( function( object ) {
        object.frustumCulled = false;
        object.castShadow = true;
        object.receiveShadow = false;
      });

      //page1
      gsap.set('#svg-wrapper', {opacity:0})
      gsap.set('#text-2-tag1', {opacity:0})
      gsap.set('#text-2-tag2', {opacity:0})
      gsap.set('#text-2-tag3', {opacity:0})
      gsap.set('#text-2-tag4', {opacity:0})

      gsap.set('#scroll', {opacity:0})

//      gsap.set('#text-3-tag1', {opacity:0})
//      gsap.set('#text-3-tag2', {opacity:0})

      //page2
      gsap.set(camera.position,{x:0,y:1.1,z:1.5,})
      gsap.set(camera.rotation,{x:0.2,y:0,z:0,duration: 1,})

      gsap.set('#detailsbackgroundgradient', {opacity:0})
      gsap.set('#detailsbackgrounddots', {opacity:0})

      gsap.set('#details-title', {opacity:0})
      gsap.set('#age', {opacity:0})
      gsap.set('#height', {opacity:0})
      gsap.set('#weight', {opacity:0})

      gsap.set('#certificate-title', {opacity:0})
      gsap.set('#certificatecontent', {opacity:0})

      gsap.set('#language-title', {opacity:0})
      gsap.set('#chi', {opacity:0})
      gsap.set('#eng', {opacity:0})
      gsap.set('#mas', {opacity:0})

      gsap.set('#modelnameeric', {opacity:0})
      gsap.set('#modelserial', {opacity:0})


      gsap.set('#designer-title', {opacity:0})
      gsap.set('#designerdetail', {opacity:0})
      gsap.set('#profile', {opacity:0})

      gsap.set('#ability-title', {opacity:0})
      gsap.set('#ps', {opacity:0})
      gsap.set('#ai', {opacity:0})
      gsap.set('#pr', {opacity:0})
      gsap.set('#ae', {opacity:0})
      gsap.set('#fm', {opacity:0})
      gsap.set('#wp', {opacity:0})
      gsap.set('#un', {opacity:0})

      gsap.set('#gradientline1', {width:0})
      gsap.set('#gradientline2', {width:0})
      gsap.set('#gradientline3', {width:0})
      gsap.set('#gradientline4', {height:0})
      gsap.set('#gradientline5', {width:0})
      gsap.set('#gradientline6', {width:0})

      //page3
      gsap.set('#experiencetitleandprogress', {opacity:0})
      gsap.set('#experiencebackandnext', {opacity:0})
      gsap.set('#gradientline7', {width:0})
      gsap.set('#experiencecard-1', {y:"10rem",opacity:0})
      gsap.set('#experiencecard-2', {y:"10rem",opacity:0})
      gsap.set('#experiencecard-3', {y:"10rem",opacity:0})
      gsap.set('#experiencecard-4', {y:"10rem",opacity:0})
      gsap.set('#experiencecard-5', {y:"10rem",opacity:0})

      //page4
      gsap.set('#allbackground', {scale:1})
      gsap.set('#earthbackground', {scale:1,top:"2rem"})
      
      gsap.set('#order-left', {x:"-50rem",y:0})
      gsap.set('#order-right', {x:"50rem",y:0})
      gsap.set('#gradientline8', {width:0})
      gsap.set('#gradientline9', {width:0})
      gsap.set('#gradientline10', {width:0})
      gsap.set('#orderbg', {opacity:0})

      gsap.set('#progress', {width:"12%"})

      var textureLoader1 = new THREE.TextureLoader(loadingManager);
      var normalbodyDiffuse = textureLoader1.load( "/image/normal/normal_body_diffuse.jpg");
      normalbodyDiffuse.flipY = false;
      var normalbodyGlossiness = textureLoader1.load( "/image/normal/normal_body_glossiness.jpg");
      normalbodyGlossiness.flipY = false;
      var normalbodyEmissive = textureLoader1.load( "/image/normal/normal_body_emissive.jpg");
      normalbodyEmissive.flipY = false;
      var normalheadlegDiffuse = textureLoader1.load( "/image/normal/normal_headleg_diffuse.jpg");
      normalheadlegDiffuse.flipY = false;
      var normalheadlegGlossiness = textureLoader1.load( "/image/normal/normal_headleg_glossiness.jpg");
      normalheadlegGlossiness.flipY = false;
      var normalheadlegEmissive = textureLoader1.load( "/image/normal/normal_headleg_emissive.jpg");
      normalheadlegEmissive.flipY = false;


      var textureLoader2 = new THREE.TextureLoader(loadingManager);
      var bluebodyDiffuse = textureLoader2.load( "/image/blue/blue_body_diffuse.jpg");
      bluebodyDiffuse.flipY = false;
      var bluebodyGlossiness = textureLoader2.load( "/image/blue/blue_body_glossiness.jpg");
      bluebodyGlossiness.flipY = false;
      var bluebodyEmissive = textureLoader2.load( "/image/blue/blue_body_emissive.jpg");
      bluebodyEmissive.flipY = false;
      var blueheadlegDiffuse = textureLoader2.load( "/image/blue/blue_headleg_diffuse.jpg");
      blueheadlegDiffuse.flipY = false;
      var blueheadlegGlossiness = textureLoader2.load( "/image/blue/blue_headleg_glossiness.jpg");
      blueheadlegGlossiness.flipY = false;
      var blueheadlegEmissive = textureLoader2.load( "/image/blue/blue_headleg_emissive.jpg");
      blueheadlegEmissive.flipY = false;

      var textureLoader3 = new THREE.TextureLoader(loadingManager);
      var whitebodyDiffuse = textureLoader3.load( "/image/white/white_body_diffuse.jpg");
      whitebodyDiffuse.flipY = false;
      var whitebodyGlossiness = textureLoader3.load( "/image/white/white_body_glossiness.jpg");
      whitebodyGlossiness.flipY = false;
      var whitebodyEmissive = textureLoader3.load( "/image/white/white_body_emissive.jpg");
      whitebodyEmissive.flipY = false;
      var whiteheadlegDiffuse = textureLoader3.load( "/image/white/white_headleg_diffuse.jpg");
      whiteheadlegDiffuse.flipY = false;
      var whiteheadlegGlossiness = textureLoader3.load( "/image/white/white_headleg_glossiness.jpg");
      whiteheadlegGlossiness.flipY = false;
      var whiteheadlegEmissive = textureLoader3.load( "/image/white/white_headleg_emissive.jpg");
      whiteheadlegEmissive.flipY = false;

      var textureLoader4 = new THREE.TextureLoader(loadingManager);
      var redbodyDiffuse = textureLoader4.load( "/image/red/red_body_diffuse.jpg");
      redbodyDiffuse.flipY = false;
      var redbodyGlossiness = textureLoader4.load( "/image/red/red_body_glossiness.jpg");
      redbodyGlossiness.flipY = false;
      var redbodyEmissive = textureLoader4.load( "/image/red/red_body_emissive.jpg");
      redbodyEmissive.flipY = false;
      var redheadlegDiffuse = textureLoader4.load( "/image/red/red_headleg_diffuse.jpg");
      redheadlegDiffuse.flipY = false;
      var redheadlegGlossiness = textureLoader4.load( "/image/red/red_headleg_glossiness.jpg");
      redheadlegGlossiness.flipY = false;
      var redheadlegEmissive = textureLoader4.load( "/image/red/red_headleg_emissive.jpg");
      redheadlegEmissive.flipY = false;

        ScrollTrigger.create({
          trigger: "#cover",
          /*snap:0.5,*/
          start: "top center",
          end: "center top",
          scrub: true,
          invalidateOnRefresh: true,
          onToggle: ( self ) => {
            const clips = spacesuit.animations;
            mixer= new THREE.AnimationMixer(spacesuit.scene);
            const idleClip = THREE.AnimationClip.findByName(clips, 'Idle');
            const idleAction = mixer.clipAction( idleClip );
            const rotateClip = THREE.AnimationClip.findByName(clips, 'Rotate');
            const rotateAction = mixer.clipAction( rotateClip );
            idleAction.timeScale = 0.5;
            if ( self.isActive ){
              idleAction.reset();
              idleAction.play();
              rotateAction.crossFadeTo( idleAction, 1 );

              gsap.to('#progress', {width:"12%",duration: 0.5})

              gsap.to(camera.position,{x:0,y:1.1,z:1.5,duration: 1.5,})
              

              gsap.to('#svg-wrapper', {opacity:1, duration: 1, delay: 0.25})

              gsap.to('#text-2-tag1', {opacity:1, duration: 1, delay: 0.5})
              gsap.to('#text-2-tag2', {opacity:1, duration: 1, delay: 0.75})
              gsap.to('#text-2-tag3', {opacity:1, duration: 1, delay: 1})
              gsap.to('#text-2-tag4', {opacity:1, duration: 1, delay: 1.25})

              gsap.to('#scroll', {opacity:1, duration: 1, delay: 1.75})

//              gsap.to('#text-3-tag1', {opacity:1, duration: 1, delay: 0.25})
//              gsap.to('#text-3-tag2', {opacity:1, duration: 1, delay: 0.25})

              gsap.from(".line span", 1.8, {y: 100,ease: "power4.out",delay: 1,skewY: 7,stagger: {amount: 0.3}})

              const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
              model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
              model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
              model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
              model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

              const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
              model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
              model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;
              model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
              model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;
            }else{
              gsap.to('#svg-wrapper', {opacity:0, duration: 0.25})
              
              gsap.to('#text-2-tag1', {opacity:0, duration: 0.25})
              gsap.to('#text-2-tag2', {opacity:0, duration: 0.25})
              gsap.to('#text-2-tag3', {opacity:0, duration: 0.25})
              gsap.to('#text-2-tag4', {opacity:0, duration: 0.25})

              gsap.to('#scroll', {opacity:0, duration: 0.25})

//              gsap.to('#text-3-tag1', {opacity:0, duration: 0.25})
//              gsap.to('#text-3-tag2', {opacity:0, duration: 0.25})
            }
          }
        })

        ScrollTrigger.create({
          trigger: "#details",
          /*snap:0.5,*/
          start: "top center",
          end: "center top",
          onToggle: ( self ) => {
            
            const clips = spacesuit.animations;
            mixer= new THREE.AnimationMixer(spacesuit.scene);
            const idleClip = THREE.AnimationClip.findByName(clips, 'Idle');
            const idleAction = mixer.clipAction( idleClip );
            const idle2Clip = THREE.AnimationClip.findByName(clips, 'Idle2');
            const idle2Action = mixer.clipAction( idle2Clip );
            const rotateClip = THREE.AnimationClip.findByName(clips, 'Rotate');
            const rotateAction = mixer.clipAction( rotateClip );
            const turnbackClip = THREE.AnimationClip.findByName(clips, 'Walkturnback');
            const turnbackAction = mixer.clipAction( turnbackClip );

            rotateAction.loop = THREE.LoopRepeat;
            rotateAction.timeScale = 0.5;
            turnbackAction.timeScale = 0.5;

            if ( self.isActive ){
              rotateAction.reset();
              rotateAction.play();
              idle2Action.crossFadeTo( rotateAction, 1 ); 
              idleAction.crossFadeTo( rotateAction, 1 ); 
 
              gsap.to('#progress', {width:"37.5%",duration: 0.5})
              
              gsap.to(camera.position,{x:0,y:1.35,z:1,duration: 1.5,})

              gsap.to('#detailsbackgroundgradient', {opacity:1, duration:2.5 })
              gsap.to('#detailsbackgrounddots', {opacity:0.65, duration: 2})
              
              gsap.to('#details-title', {opacity:1, duration: 1, delay: 0.1})
              gsap.to('#age', {opacity:1, duration: 1, delay: 0.2})
              gsap.to('#height', {opacity:1, duration: 1, delay: 0.3})
              gsap.to('#weight', {opacity:1, duration: 1, delay: 0.4})

              gsap.to('#certificate-title', {opacity:1, duration: 1, delay: 0.5})
              gsap.to('#certificatecontent', {opacity:1, duration: 1, delay: 0.6})

              gsap.to('#language-title', {opacity:1, duration: 1, delay: 0.7})
              gsap.to('#chi', {opacity:1, duration: 1, delay: 0.8})
              gsap.to('#eng', {opacity:1, duration: 1, delay: 0.9})
              gsap.to('#mas', {opacity:1, duration: 1, delay: 1})
              
              gsap.to('#designer-title', {opacity:1, duration: 1, delay: 0.1})
              gsap.to('#designerdetail', {opacity:1, duration: 1, delay: 0.2})
              gsap.to('#profile', {opacity:1, duration: 1, delay: 0.3})

              gsap.to('#ability-title', {opacity:1, duration: 1, delay: 0.4})
              gsap.to('#ps', {opacity:1, duration: 1, delay: 0.5})
              gsap.to('#ai', {opacity:1, duration: 1, delay: 0.6})
              gsap.to('#pr', {opacity:1, duration: 1, delay: 0.7})
              gsap.to('#ae', {opacity:1, duration: 1, delay: 0.8})
              gsap.to('#fm', {opacity:1, duration: 1, delay: 0.9})
              gsap.to('#wp', {opacity:1, duration: 1, delay: 1})
              gsap.to('#un', {opacity:1, duration: 1, delay: 1.1})

              gsap.to('#modelnameeric', {opacity:1, duration: 2, delay: 1})
              gsap.to('#modelserial', {opacity:1, duration: 2, delay: 1})

              gsap.to('#gradientline1', {width:"32rem",duration: 1, delay: 0.1})
              gsap.to('#gradientline2', {width:"11rem",duration: 1, delay: 0.5})
              gsap.to('#gradientline3', {width:"11rem",duration: 1, delay: 0.7})
              gsap.to('#gradientline4', {height:"17rem",duration: 1, delay: 0.1})
              gsap.to('#gradientline5', {width:"21.5rem",duration: 1, delay: 0.1})
              gsap.to('#gradientline6', {width:"11rem",duration: 1, delay: 0.4})

              document.addEventListener('mousemove', onMouseMove, false);

              const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
              model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
              model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
              model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
              model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

              const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
              model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
              model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;
              model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
              model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;

            }else{

              gsap.to('#detailsbackgroundgradient', {opacity:0, duration: 1.5})
              gsap.to('#detailsbackgrounddots', {opacity:0, duration: 1})

              gsap.to('#details-title', {opacity:0, duration: 0.5})
              gsap.to('#age', {opacity:0, duration: 0.5})
              gsap.to('#height', {opacity:0, duration: 0.5})
              gsap.to('#weight', {opacity:0, duration: 0.5})

              gsap.to('#certificate-title', {opacity:0, duration: 0.5})
              gsap.to('#certificatecontent', {opacity:0, duration: 0.5})

              gsap.to('#language-title', {opacity:0, duration: 0.5})
              gsap.to('#chi', {opacity:0, duration: 0.5})
              gsap.to('#eng', {opacity:0, duration: 0.5})
              gsap.to('#mas', {opacity:0, duration: 0.5})

              gsap.to('#designer-title', {opacity:0, duration: 0.5})
              gsap.to('#designerdetail', {opacity:0, duration: 0.5})
              gsap.to('#profile', {opacity:0, duration: 0.5})

              gsap.to('#ability-title', {opacity:0, duration: 0.5})
              gsap.to('#ps', {opacity:0, duration: 0.5})
              gsap.to('#ai', {opacity:0, duration: 0.5})
              gsap.to('#pr', {opacity:0, duration: 0.5})
              gsap.to('#ae', {opacity:0, duration: 0.5})
              gsap.to('#fm', {opacity:0, duration: 0.5})
              gsap.to('#wp', {opacity:0, duration: 0.5})
              gsap.to('#un', {opacity:0, duration: 0.5})

              gsap.to('#modelnameeric', {opacity:0, duration: 0.5})
              gsap.to('#modelserial', {opacity:0, duration: 0.5})

              gsap.to('#gradientline1', {width:0, duration: 0.5})
              gsap.to('#gradientline2', {width:0, duration: 0.5})
              gsap.to('#gradientline3', {width:0, duration: 0.5})
              gsap.to('#gradientline4', {height:0, duration: 0.5})
              gsap.to('#gradientline5', {width:0, duration: 0.5})
              gsap.to('#gradientline6', {width:0, duration: 0.5})

              document.removeEventListener('mousemove', onMouseMove, false);
            }
          }
        })

        ScrollTrigger.create({
          trigger: "#experience",
          /*snap:0.5,*/
          start: "top center",
          end: "center top",
          onToggle: ( self ) => {
            
            const clips = spacesuit.animations;
            mixer= new THREE.AnimationMixer(spacesuit.scene);
            const idle2Clip = THREE.AnimationClip.findByName(clips, 'Idle2');
            const idle2Action = mixer.clipAction( idle2Clip );
            const rotateClip = THREE.AnimationClip.findByName(clips, 'Rotate');
            const rotateAction = mixer.clipAction( rotateClip );
            const turnbackClip = THREE.AnimationClip.findByName(clips, 'Walkturnback');
            const turnbackAction = mixer.clipAction( turnbackClip );

            rotateAction.timeScale = 0.5;
            turnbackAction.timeScale = 0.5;
            idle2Action.timeScale = 0.5;

            if ( self.isActive ){
              turnbackAction.reset();
              turnbackAction.play();
              turnbackAction.loop = THREE.LoopOnce;
              turnbackAction.clampWhenFinished = true;
              rotateAction.crossFadeTo( turnbackAction, 1 );
              idle2Action.crossFadeTo( turnbackAction, 1 );

              gsap.to('#progress', {width:"65.5%",duration: 0.5})

              gsap.to(camera.position,{x:0,y:0.75,z:2,duration: 2,})

              mixer.addEventListener('finished',function (e){
              if (e.action._clip.name === 'Walkturnback'){          
                idle2Action.reset();
                idle2Action.play();
                rotateAction.crossFadeTo( idle2Action, 1 ); 
                }
              })

              gsap.to('#experiencetitleandprogress', {opacity:1, duration: 1, delay: 0.6})
              gsap.to('#experiencebackandnext', {opacity:1, duration: 1, delay: 0.6})
              gsap.to('#gradientline7', {width:"17rem", duration: 1, delay: 0.7})
              gsap.to('#experiencecard-1', {y:0,opacity:1, duration: 1, delay: 0.1})
              gsap.to('#experiencecard-2', {y:0,opacity:1, duration: 1, delay: 0.2})
              gsap.to('#experiencecard-3', {y:0,opacity:1, duration: 1, delay: 0.3})
              gsap.to('#experiencecard-4', {y:0,opacity:1, duration: 1, delay: 0.4})
              gsap.to('#experiencecard-5', {y:0,opacity:1, duration: 1, delay: 0.5})
            }else{
              gsap.to('#experiencetitleandprogress', {opacity:0, duration: 0.5})
              gsap.to('#experiencebackandnext', {opacity:0, duration: 0})
              gsap.set('#gradientline7', {width:0, duration: 0.5})
              gsap.to('#experiencecard-1', {y:"-10rem",opacity:0, duration: 0.5})
              gsap.to('#experiencecard-2', {y:"-10rem",opacity:0, duration: 0.5})
              gsap.to('#experiencecard-3', {y:"-10rem",opacity:0, duration: 0.5})
              gsap.to('#experiencecard-4', {y:"-10rem",opacity:0, duration: 0.5})
              gsap.to('#experiencecard-5', {y:"-10rem",opacity:0, duration: 0.5})
            }
          }
        })

        ScrollTrigger.create({
          trigger: "#order",
          /*snap:0.5,*/
          start: "top center",
          end: "center top",
          onToggle: ( self ) => {
            
            const clips = spacesuit.animations;
            mixer= new THREE.AnimationMixer(spacesuit.scene);
            const idleClip = THREE.AnimationClip.findByName(clips, 'Idle');
            const idleAction = mixer.clipAction( idleClip );
            const idle2Clip = THREE.AnimationClip.findByName(clips, 'Idle2');
            const idle2Action = mixer.clipAction( idle2Clip );
            const turnbackClip = THREE.AnimationClip.findByName(clips, 'Walkturnback');
            const turnbackAction = mixer.clipAction( turnbackClip );
            const dance1Clip = THREE.AnimationClip.findByName(clips, 'Dance1');
            const dance1Action = mixer.clipAction( dance1Clip );
            const dance2Clip = THREE.AnimationClip.findByName(clips, 'Dance2');
            const dance2Action = mixer.clipAction( dance2Clip );
            const dance3Clip = THREE.AnimationClip.findByName(clips, 'Dance3');
            const dance3Action = mixer.clipAction( dance3Clip );
            const dance4Clip = THREE.AnimationClip.findByName(clips, 'Dance4');
            const dance4Action = mixer.clipAction( dance4Clip );

            idleAction.timeScale = 0.5;
            dance1Action.timeScale = 0.5;
            dance2Action.timeScale = 0.5;
            dance3Action.timeScale = 0.5;
            dance4Action.timeScale = 0.5;

            const btn1 = document.getElementById("ordercoloroption1");
            const btn2 = document.getElementById("ordercoloroption2");
            const btn3 = document.getElementById("ordercoloroption3");
            const btn4 = document.getElementById("orderdanceoption1");
            const btn5 = document.getElementById("orderdanceoption2");
            const btn6 = document.getElementById("orderdanceoption3");
            const btn7 = document.getElementById("orderdanceoption4");

            var clicked = false;
            var colorclicked = false;

            if ( self.isActive ){
              idleAction.reset();
              idleAction.play();
              idle2Action.crossFadeTo( idleAction, 1 );
              turnbackAction.crossFadeTo( idleAction, 1 );
              
              gsap.to('#progress', {width:"100%",duration: 0.5})

              gsap.to(camera.position,{x:0.5,y:0.8,z:2.9,duration: 1})
              gsap.to(camera.rotation,{x:0.1,y:0,z:0,duration: 1})
              gsap.to('#allbackground', {scale:1.25,top:"-5rem", duration: 1})
              gsap.to('#earthbackground', {scale:1.25,top:"0rem", duration: 1})
        
              gsap.to('#order-left', {x:0,y:0,duration: 1,delay: 0.5})
              gsap.to('#order-right', {x:0,y:0,duration: 1,delay: 0.5})

              gsap.to('#gradientline8', {width:"17rem", duration: 1, delay: 1})
              gsap.to('#gradientline9', {width:"17rem", duration: 1, delay: 1.1})
              gsap.to('#gradientline10', {width:"17rem", duration: 1, delay: 1.2})
              gsap.to('#orderbg', {opacity:0.5, duration: 1})

              btn1.addEventListener('click', function handleClick() {
                if(colorclicked){

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;

                  model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;

                  colorclicked = false;
                  btn1.classList.remove("active2");

                  btn2.disabled = false;
                  btn3.disabled = false;
                }else{
                  colorclicked = !colorclicked;

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map  = bluebodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = bluebodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = bluebodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map  = blueheadlegDiffuse;
                  model.getObjectByName("Mesh001_1").material.roughnessMap = blueheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = blueheadlegEmissive;
                  //console.log('Patchuv',texturePatchUV1)
                  //console.log('Modal',model);
                  colorclicked = true;
                  btn1.classList.add("active2");
                  
                  btn2.disabled = true;
                  btn3.disabled = true;
                }
              });

              btn2.addEventListener('click', function handleClick() {
                if(colorclicked){

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;

                  model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;

                  colorclicked = false;
                  btn2.classList.remove("active2");

                  btn1.disabled = false;
                  btn3.disabled = false;
                }else{
                  colorclicked = !colorclicked;

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map  = whitebodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = whitebodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = whitebodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map  = whiteheadlegDiffuse;

                  model.getObjectByName("Mesh001_1").material.roughnessMap = whiteheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = whiteheadlegEmissive;
                  //console.log('Patchuv',texturePatchUV1)
                  //console.log('Modal',model);
                  colorclicked = true;
                  btn2.classList.add("active2");
                  
                  btn1.disabled = true;
                  btn3.disabled = true;
                }
              });

              btn3.addEventListener('click', function handleClick() {
                if(colorclicked){

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;

                  model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;

                  colorclicked = false;
                  btn3.classList.remove("active2");


                  btn1.disabled = false;
                  btn2.disabled = false;
                }else{
                  colorclicked = !colorclicked;

                  const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
                  model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
                  model.getObjectByName("Mesh001").material.map  = redbodyDiffuse;
                  model.getObjectByName("Mesh001").material.roughnessMap = redbodyGlossiness;
                  model.getObjectByName("Mesh001").material.emissiveMap = redbodyEmissive;

                  const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
                  model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
                  model.getObjectByName("Mesh001_1").material.map  = redheadlegDiffuse;
                  model.getObjectByName("Mesh001_1").material.roughnessMap = redheadlegGlossiness;
                  model.getObjectByName("Mesh001_1").material.emissiveMap = redheadlegEmissive;
                  //console.log('Patchuv',texturePatchUV1)
                  //console.log('Modal',model);
                  colorclicked = true;
                  btn3.classList.add("active2");
                  
                  btn1.disabled = true;
                  btn2.disabled = true;
                }
              });

              btn4.addEventListener('click', function handleClick() {
                if(clicked){
                  idleAction.reset();
                  idleAction.play();
                  dance1Action.crossFadeTo( idleAction, 0.5 );
                  clicked = false;
                  btn4.classList.remove("active2");

                  btn5.disabled = false;
                  btn6.disabled = false;
                  btn7.disabled = false;
                }else{
                  clicked = !clicked;
                  dance1Action.reset();            
                  dance1Action.play();
                  dance2Action.stop();
                  dance3Action.stop();
                  dance4Action.stop();
                  idleAction.crossFadeTo( dance1Action, 1 );
                  idle2Action.crossFadeTo( dance1Action, 1 );
                  dance2Action.crossFadeTo( dance1Action, 1 );
                  dance3Action.crossFadeTo( dance1Action, 1 );
                  dance4Action.crossFadeTo( dance1Action, 1 );
                  clicked = true;
                  btn4.classList.add("active2");
                  
                  btn5.disabled = true;
                  btn6.disabled = true;
                  btn7.disabled = true;
                }
              });

              btn5.addEventListener('click', function handleClick() {
                if(clicked){
                  idleAction.reset();
                  idleAction.play();
                  dance2Action.crossFadeTo( idleAction, 0.5 );
                  clicked = false;
                  btn5.classList.remove("active2");

                  btn4.disabled = false;
                  btn6.disabled = false;
                  btn7.disabled = false;
                }else{
                  clicked = !clicked;
                  dance2Action.reset();            
                  dance2Action.play();
                  dance1Action.stop();
                  dance3Action.stop();
                  dance4Action.stop();
                  idleAction.crossFadeTo( dance2Action, 1 );
                  idle2Action.crossFadeTo( dance2Action, 1 );
                  dance1Action.crossFadeTo( dance2Action, 1 );
                  dance3Action.crossFadeTo( dance2Action, 1 );
                  dance4Action.crossFadeTo( dance2Action, 1 );
                  clicked = true;
                  btn5.classList.add("active2");
                              
                  btn4.disabled = true;
                  btn6.disabled = true;
                  btn7.disabled = true;
                }
              })
      
              btn6.addEventListener('click', function handleClick() {
                if(clicked){
                  idleAction.reset();
                  idleAction.play();
                  dance3Action.crossFadeTo( idleAction, 0.5 );
                  clicked = false;
                  btn6.classList.remove("active2");

                  btn4.disabled = false;
                  btn5.disabled = false;
                  btn7.disabled = false;
                }else{
                  clicked = !clicked;
                  dance3Action.reset();            
                  dance3Action.play();
                  dance1Action.stop();
                  dance2Action.stop();
                  dance4Action.stop();
                  idleAction.crossFadeTo( dance3Action, 1 );
                  idle2Action.crossFadeTo( dance3Action, 1 );
                  dance1Action.crossFadeTo( dance3Action, 1 );
                  dance2Action.crossFadeTo( dance3Action, 1 );
                  dance4Action.crossFadeTo( dance3Action, 1 );
                  clicked = true;
                  btn6.classList.add("active2");

                  btn4.disabled = true;
                  btn5.disabled = true;
                  btn7.disabled = true;
                }
              })

              btn7.addEventListener('click', function handleClick() {
                if(clicked){
                  idleAction.reset();
                  idleAction.play();
                  dance4Action.crossFadeTo( idleAction, 0.5 );
                  clicked = false;
                  btn7.classList.remove("active2");

                  btn4.disabled = false;
                  btn5.disabled = false;
                  btn6.disabled = false;
                }else{
                  clicked = !clicked;
                  dance4Action.reset();            
                  dance4Action.play();
                  dance1Action.stop();
                  dance2Action.stop();
                  dance3Action.stop();
                  idleAction.crossFadeTo( dance4Action, 1 );
                  idle2Action.crossFadeTo( dance4Action, 1 );
                  dance1Action.crossFadeTo( dance4Action, 1 );
                  dance2Action.crossFadeTo( dance4Action, 1 );
                  dance3Action.crossFadeTo( dance4Action, 1 );
                  clicked = true;
                  btn7.classList.add("active2");
                  
                  btn4.disabled = true;
                  btn5.disabled = true;
                  btn6.disabled = true;
                }
              })
              btn1.disabled = false;
              btn2.disabled = false;
              btn3.disabled = false;
              btn4.disabled = false;
              btn5.disabled = false;
              btn6.disabled = false;
              btn7.disabled = false;


            }else{
              const texturePatchUV1 = model.getObjectByName("Mesh001").geometry.attributes.uv.array;
              model.getObjectByName("Mesh001").geometry.attributes.uv.array = texturePatchUV1;
              model.getObjectByName("Mesh001").material.map = normalbodyDiffuse;
              model.getObjectByName("Mesh001").material.roughnessMap = normalbodyGlossiness;
              model.getObjectByName("Mesh001").material.emissiveMap = normalbodyEmissive;

              const texturePatchUV2 = model.getObjectByName("Mesh001").geometry.attributes.uv2.array;
              model.getObjectByName("Mesh001_1").geometry.attributes.uv2.array = texturePatchUV2;
              model.getObjectByName("Mesh001_1").material.map = normalheadlegDiffuse;
              model.getObjectByName("Mesh001_1").material.roughnessMap = normalheadlegGlossiness;
              model.getObjectByName("Mesh001_1").material.emissiveMap = normalheadlegEmissive;

              gsap.to(camera.rotation,{x:0.2,y:0,z:0,duration: 0.5})
              gsap.to('#allbackground', {scale:1,top:0, duration: 0.5})
              gsap.to('#earthbackground', {scale:1,top:"2rem", duration: 0.5})      

              gsap.to('#order-left', {x:"-50rem",y:0,z:0, duration: 0.5,delay:0.5})
              gsap.to('#order-right', {x:"50rem",y:0,z:0, duration: 0.5,delay:0.5})

              gsap.to('#gradientline8', {width:0, duration: 0.5})
              gsap.to('#gradientline9', {width:0, duration: 0.5})
              gsap.to('#gradientline10', {width:0, duration: 0.5})
              gsap.to('#orderbg', {opacity:0, duration: 0.5})

              clicked = false;

              btn1.classList.remove("active2");
              btn2.classList.remove("active2");
              btn3.classList.remove("active2");
              btn4.classList.remove("active2");
              btn5.classList.remove("active2");
              btn6.classList.remove("active2");
              btn7.classList.remove("active2");

              btn1.disabled = true;
              btn2.disabled = true;
              btn3.disabled = true;
              btn4.disabled = true;
              btn5.disabled = true;
              btn6.disabled = true;
              btn7.disabled = true;
            }
          }
        })
        scene.add( spacesuit.scene );
      });
    }

    //RESIZE
    window.addEventListener( 'resize', resize, false);
    function resize(){
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize( window.innerWidth, window.innerHeight );
    }

    function onMouseMove(event) {
      event.preventDefault();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
      vector.unproject(camera);
      var dir = vector.sub(camera.position).normalize();
      var distance = -camera.position.z / dir.z;
      var pos = camera.position.clone().add(dir.multiplyScalar(distance));

      light.position.copy(new THREE.Vector3(pos.x, pos.y, pos.z + 2));
    }
    scene.add(mouseMesh);

    function animate() {
      requestAnimationFrame( animate );
      var delta = clock.getDelta();
      if ( mixer ) mixer.update( delta );
      renderer.render( scene, camera );
    }
    animate();
  }
}

</script>


