  
  <script>
  import gsap from "gsap/dist/gsap";
    export default {
      mounted () {
        const cursorBig = document.getElementById('cursor-big'),
              cursorSmall = document.getElementById('cursor-small'),
              links = document.getElementsByTagName("a"),
              button = document.getElementsByTagName("button"),
              withClassHover = document.getElementsByClassName("cursor-hover"),
              withHover = [...links, ...withClassHover, ...button];
  
        // Event Listeners
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mousedown", onMouseHover);
        document.addEventListener("mouseup", onMouseHoverOut);
        document.addEventListener("mouseenter", () => {
          cursorBig.style.opacity = 1;
          cursorSmall.style.opacity = 1;
        });
        document.addEventListener("mouseleave", () => {
          cursorBig.style.opacity = 0;
          cursorSmall.style.opacity = 0;
        });
        withHover.forEach((element) => {
          element.addEventListener("mouseover", onMouseHover);
          element.addEventListener("mouseout", onMouseHoverOut);
        })
  
        gsap.set(cursorBig,{
            scale: 0.8,
            "--b": "#06F7A1",
            mixBlendMode: "normal",
          });

        // Event Handlers
        function onMouseMove(e) {
          cursorSmall.style.opacity = 1;
          gsap.to(cursorBig, 0.4, {
            x: e.clientX - 18,
            y: e.clientY - 18
          });
          gsap.to(cursorSmall, 0.1, {
            x: e.clientX - 4,
            y: e.clientY - 4
          });
        }

        gsap.set(cursorBig, {
            scale: 1,
            backgroundSize: "6px 6px"
          });

        function onMouseHover() {
          gsap.to(cursorBig, 0.4, {
            scale: 1.2,
            "--b": "#ffffff",
            mixBlendMode: "plus-lighter",
          });
        }
        function onMouseHoverOut() {
          gsap.to(cursorBig, 0.4, {
            scale: 0.8,
            "--b": "#06F7A1",
            mixBlendMode: "normal",
          });
        }
      }
    };
  </script>
  
  <style land="css">
   
   @media only screen and (orientation: portrait) {
    .custom-cursor__ball--small,
    .custom-cursor__ball--big,
    .custom-cursor__ball {
      display: none;      
    }
    
  }

   
  .custom-cursor__ball {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    filter: drop-shadow(0 0 0.5rem rgb(24 254 254 / 90%));
  }

  @property --b {
    syntax: '<angle>';
    inherits: false;
  }

  .custom-cursor__ball--big {
    scale: 0.8;
    margin: 0.25rem;
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background-image:radial-gradient( var(--b) 20%, transparent 0), radial-gradient(var(--b) 20%, transparent 0);
    background-size: 1px 1px;
    filter: drop-shadow(0 0 0.5rem rgb(24 254 254 / 90%));
    mix-blend-mode: normal;
  }

  .custom-cursor__ball--small {
    content: "";
    width: 6px;
    height: 6px;
    background: #78fff1;
    border-radius: 50%;
  }

  </style>